<template>
  <div class="auth-layout-wrap h-100 w-100 mx-auto" style="
      background: #000000;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      max-height: 100vh;
      background-position: 100% 100%;
      max-width: 440px;

      
    ">
    <div class="col-11 h-100 d-flex flex-column flex-grow-1">
      <div style="margin-top: 9rem;"></div>
      <div class="mb-4 w-100 text-center">
        <img class="" :src="logo" style=" width:200.25px; height:70px;" />
      </div>    
      <h3 class="head_style">{{ $t("login_wallet") }}</h3>

      <b-form @submit.prevent="checkRef" class="d-flex flex-column ">
        <b-button type="submit" class="mt-4 mb-2 btn-curved-wallet" variant="primary" block :disabled="loading"
          style="padding:4px 10px 4px 10px"> 
          <img class="mr-2" src="../../../assets/images/etnex_login/signin_icon.png" alt="" />
            <!-- <i class="fas fa-wallet"></i> -->
            <span> 
              {{ $t("connect") }}
            </span>
        </b-button>
        <div v-once class="typo__p" v-if="loading">
          <div class="spinner sm spinner-light mt-3"></div>
        </div>
        <div class="container p-0 mt-4 mb-3">
          <div class="line-1"></div>
          <div class="text-white text-16">{{ $t("with_referralid") }}</div>
          <div class="line-2"></div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12 mb-3">
            <h5 class="label-form2 mb-2 text-14">
              {{ $t("Referral ID") }}
            </h5>
            <input type="text" class="form-control label-input" v-model="ref_id" />
          </div>
        </div>
      </b-form>
      <!-- <router-link to="/web/settings/forget-password" tag="a" class="text-white text-center mt-5 text-14">{{
        $t("forget_password") }}?
      </router-link> -->
      <div class="flex-grow-1"></div>
    </div>
    <b-modal id="modal-maintenance" size="md" centered :title="$t('system_maintenance')" :hide-footer="true"
      style="background-color: #5f646e !important" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <b-form class="mx-5">
        <b-row align-h="center">
          <b-col md="12 mb-30">
            <b-row align-h="center" style="margin-bottom: 10px">
              <div class="form-group">
                <i class="fa fa-cog" style="font-size: 100px; color: purple"></i>
              </div>
              <div class="col-sm-12">
                <center>
                  {{ $t("maintanence") }}
                </center>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <Dialog ref="msg"></Dialog>
  </div>
</template>
<script>
import { getDownloadLink, checkRefAddress, walletLogin } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import { mapGetters, mapActions } from "vuex";
import { useMetatool } from '../../../system/crypto/config'
import Dialog from "../../../components/dialog.vue";
let metaTool;
export default {
  metaInfo: {
    title: "Sign In",
  },
  data() {
    return {
      username: "",
      password: "",
      ref_id: "",
      bgImage: require("../../../assets/images/etnex_home/bg2.png"),
      logo: require("../../../assets/images/logo.png"),
    };
  },
  computed: {
    ...mapGetters(["loggedInUser", "loading", "error"]),
  },
  components: {
    Dialog,
  },
  methods: {
    ...mapActions(["login", "changeLan"]),
    checkRef() {
      if (this.ref_id === null || !this.ref_id.trim()) {
        this.connect();
      } else {
        this._checkRefAddress();
      }
    },
    _checkRefAddress() {
      let formData = new FormData();
      var self = this;
      formData.append("ref_address", this.ref_id);
      var result = checkRefAddress(formData);

      result
        .then(function (value) {
          if (value.data.error_code == 0) {
            self.connect();

          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },

    async checkAccount() {
      if (metaTool.isInit) {
        await metaTool.getCurrentAccountFromMetamask().then(async res => {
          var currentAddress = res[0];
          this.formSubmit(currentAddress);


        });

      }

    },
    connect() {
      metaTool.connectWallet().then(res => {
        if (res.length > 0) {
          this.checkAccount();

        } else {
          self.$refs.msg.makeToast("danger", self.$t('No address connected in your wallet!'));
        }

      }).catch(function (error) {
        console.log(error);
      });
    },
    async formSubmit(currentAddress) {

      let formData = new FormData();
      var self = this;
      formData.append("ref_address", this.ref_id);
      formData.append("wallet_address", currentAddress);
      var result = walletLogin(formData);

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.code == 0) {
            const newToken = value.data.data.token;
            localStorage.setItem("etnexToken", newToken);
            localStorage.setItem("username", currentAddress);
            localStorage.setItem("role_id", 2);
            self.$store.commit("setUser", newToken);
          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
    makeToast(variant = null, msg) {
      this.$bvToast.toast(msg, {
        variant: variant,
        solid: true,
      });
    },
    changeLang(lang) {
      console.log(lang);
      this.$i18n.locale = lang;
      this.changeLan(lang);
    },
    maintenance() {
      var result = getDownloadLink();
      var self = this;
      result
        .then(function (value) {
          var siteOn = value.data.data.system.SITE_ON;
          if (siteOn == 0) {
            self.$bvModal.show("modal-maintenance");
          }
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
  },
  mounted() {
    metaTool = useMetatool();

    this.maintenance();

    var url_string = window.location.href;
    var url = new URL(url_string);
    this.ref_id = url.searchParams.get("ref_id");
  },
  watch: {
    loggedInUser(val) {
      if (val && val.length > 0) {
        this.makeToast("success", this.$t("login_ok"));

        setTimeout(() => {
          this.$router.push("/web");
        }, 500);
      }
    },
    error(val) {
      if (val != null) {
        this.makeToast("danger", this.$t(val));
      }
    },
  },
};
</script>

<style>
h5#modal-maintenance___BV_modal_title_ {
  margin: auto;
}

button.close {
  display: none;
}

.spinner.sm {
  height: 2em;
  width: 2em;
}

.form-custom {
  font-size: 16px !important;
  border-radius: 0.5rem !important;
  /* height: calc(2.5rem + 2px); */
  /* color: #fff !important; */
  /* background: linear-gradient(58deg, #001c53, #001c53); */
  border: none !important;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.line-1 {
  height: 1px;
  background-color: #FFFFFF;
  flex-grow: 1;
  margin: 0 20px 0 0px;
  border-radius: 60px;
}

.line-2 {
  height: 1px;
  background-color: #FFFFFF;
  flex-grow: 1;
  margin: 0 0px 0 20px;
  border-radius: 60px;
}
</style>
